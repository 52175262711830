import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Kurulum`}</h2>
    <p>{`Geliştirmeye başlamadan önce bir Actions on Google projesi oluşturmamız ve geliştirmeye hazır hale getirmemiz gerekiyor. `}</p>
    <h3>{`Google etkinlik ayarlarınızı kontrol edin`}</h3>
    <p>{`Çalışmanın devamında geliştirdiğiniz uygulamayı Actions Simulator üzerinde test edebilmek için gerekli bazı izinleri vermeniz gerekiyor. Bu izinleri açmak için:`}</p>
    <ol>
      <li parentName="ol">{`Google `}<a parentName="li" {...{
          "href": "https://myactivity.google.com/activitycontrols"
        }}>{`Etkinlik Kontrolleri`}</a>{` sayfasına gidin`}</li>
      <li parentName="ol">{`Zaten hesabınıza giriş yapmadıysanız, giriş yapın`}</li>
      <li parentName="ol"><strong parentName="li">{`Web ve Uygulama Etkinliği`}</strong>{` (Web & App Activity) iznini aktif hale getirin ve `}<strong parentName="li">{`"Chrome geçmişini ve Google hizmetlerini kullanan site, uygulama ve cihazlardaki etkinlikleri ekle"`}</strong>{` (Include Chrome history and activity from sites, apps, and devices that use Google services.) seçeneğini işaretleyin.`}</li>
    </ol>
    <h3>{`Bir Actions projesi oluşturun`}</h3>
    <p>{`Action projenizi, Action'ınızı da içinde barındıran bir paket olarak düşünebilirsiniz. Bu Action'a bağlı başka Google Cloud hizmetleri kullanmanız halinde bunları da aynı proje içerisinde barındırabilir ve yönetimi, kolaylaştırabilirsiniz.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://console.actions.google.com/"
        }}>{`Actions konsolu`}</a>{` açın`}</li>
      <li parentName="ol"><strong parentName="li">{`New Project`}</strong>{`'e tıklayın`}</li>
      <li parentName="ol">{`Projenize bir isim verin, bu isim uygulamanızın kullanıcılar tarafından görülen ismi değil. Bu yüzden sizin tarafınızdan anlaşılabilir olması yeterli`}<br parentName="li"></br>
        <figure parentName="li" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "805px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "59.04761904761905%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB25LiWC4f/8QAGRABAAIDAAAAAAAAAAAAAAAAAQACEBES/9oACAEBAAEFAsWnbGym0n//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAADAAAAAAAAAAAAAAAAAAAAIFH/2gAIAQEABj8CWn//xAAdEAACAgEFAAAAAAAAAAAAAAAAEQExIVFhcYGh/9oACAEBAAE/IY4MaeCMhVQJFbwVH2P/2gAMAwEAAgADAAAAECz/AP/EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QeQ6X/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAIf/aAAgBAgEBPxA6zJGb/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARIUFRMdH/2gAIAQEAAT8QqBg4JUQRQPOQB5mn7GV89qJGiltbVn//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Projenizi isimlendirme",
              "title": "Projenizi isimlendirme",
              "src": "/static/441e8e4e0305fbc239a116cf14789697/daffc/1.jpg",
              "srcSet": ["/static/441e8e4e0305fbc239a116cf14789697/60342/1.jpg 210w", "/static/441e8e4e0305fbc239a116cf14789697/65f94/1.jpg 420w", "/static/441e8e4e0305fbc239a116cf14789697/daffc/1.jpg 805w"],
              "sizes": "(max-width: 805px) 100vw, 805px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Projenizi isimlendirme`}</figcaption>{`
  `}</figure></li>
      <li parentName="ol"><strong parentName="li">{`Create Project`}</strong>{`'e tıklayın`}</li>
      <li parentName="ol">{`What kind of Action do you want to build? yani `}<strong parentName="li">{`Ne tür bir Action geliştirmek istiyorsunuz?`}</strong>{` diye sorulduğunda `}<strong parentName="li">{`Custom`}</strong>{` yani `}<strong parentName="li">{`özel`}</strong>{` seçeneğini seçin`}</li>
      <li parentName="ol"><strong parentName="li">{`Next`}</strong>{`'e basın`}</li>
      <li parentName="ol">{`Herhangi bir template kullanmayacağımız için `}<strong parentName="li">{`Blank project`}</strong>{` yazan kartı seçin`}</li>
      <li parentName="ol">{`Geliştirmeye başlamak için `}<strong parentName="li">{`Start Building`}</strong>{`'e basın.`}</li>
    </ol>
    <h3>{`Bir faturalandırma hesabı bağlayın`}</h3>
    <p>{`Çalışmanın son aşamasında fulfillment'ımızı Google Cloud Functions üzerinde geliştireceğimiz için, yeni oluşturduğumuz Actions on Google projesine bir faturalandırma hesabı bağlamamız gerekiyor. Eğer projenize bir faturalandırma hesabı zaten bağlı ise diğer adıma geçebilirsiniz.`}</p>
    <p>{`Projenize bir faturalandırma hesabı eklemek için:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://console.cloud.google.com/billing"
        }}>{`Google Cloud Console faturalandırma sayfasına`}</a>{` gidin`}</li>
      <li parentName="ol"><strong parentName="li">{`Faturalandırma hesabı ekle`}</strong>{` (Add billing account) butonuna tıklayın`}</li>
      <li parentName="ol">{`Ödeme bilgilerinizi girin ve `}<strong parentName="li">{`Ücretsiz Denememi Başlat`}</strong>{` (Start My Free Trial) veya Gönder ve `}<strong parentName="li">{`Faturalandırmayı Başlat`}</strong>{` seçeneklerinden birine basın`}</li>
      <li parentName="ol">{`Sayfanın üstünde yer alan `}<strong parentName="li">{`Projelerim`}</strong>{`'e (My Projects) `}<strong parentName="li">{`tıklayın`}</strong></li>
      <li parentName="ol">{`Yukarıda oluşturduğunuz projenin yanındaki Actions'ın altındaki üç noktaya tıklayın`}</li>
      <li parentName="ol"><strong parentName="li">{`Faturalandırmayı değiştir`}</strong>{`'e (Change billing) tıklayın`}</li>
      <li parentName="ol">{`Açılan menüde yukarıdaki adımlarda oluşturduğunuz faturalandırma hesabını seçin. `}<strong parentName="li">{`Hesabı Belirle`}</strong>{`'ye (Set Account) tıklayın`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Gereksiz ödemelerden kaçınmak için, çalışmanın sonunda faturalandırma hesabınızı ve projenizi silmeyi unutmayın`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      