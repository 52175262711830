import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Sırada ne var?`}</h2>
    <p>{`Tebrikler!`}</p>
    <p>{`Artık Google Asistan için Action geliştirmenin temellerini biliyorsunuz.`}</p>
    <h3>{`Öğrendikleriniz`}</h3>
    <ul>
      <li parentName="ul">{`Actions konsolda bir Actions projesi oluşturmak`}</li>
      <li parentName="ul">{`Main invocation’a cevap eklemek`}</li>
      <li parentName="ul">{`Sahneleri, intent’leri, geçişleri, öneri butonlarını ve fulfillment’ı kullanarak bir diyalog oluşturmak`}</li>
      <li parentName="ul">{`Action’ı Actions simülatörde test etmek`}</li>
    </ul>
    <h3>{`Projenizi ve faturalandırma hesabınızı silmeyi unutmayın`}</h3>
    <blockquote>
      <p parentName="blockquote">{`Eğer kullanmaya devam etmeyeceksiniz projenizi ve faturalandırma hesabınızı silmeyi unutmayın. Aksi halde beklenmedik ödemelerle karşılaşabilirsiniz.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      