import Step1 from "../../../../content/workshops/actions-builder-ile-action-gelistirme-birinci-seviye/steps/1.mdx";
import Step2 from "../../../../content/workshops/actions-builder-ile-action-gelistirme-birinci-seviye/steps/2.mdx";
import Step3 from "../../../../content/workshops/actions-builder-ile-action-gelistirme-birinci-seviye/steps/3.mdx";
import Step4 from "../../../../content/workshops/actions-builder-ile-action-gelistirme-birinci-seviye/steps/4.mdx";
import Step5 from "../../../../content/workshops/actions-builder-ile-action-gelistirme-birinci-seviye/steps/5.mdx";
import Step6 from "../../../../content/workshops/actions-builder-ile-action-gelistirme-birinci-seviye/steps/6.mdx";
import * as React from 'react';
export default {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  React
};