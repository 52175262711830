import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Action'ın diyalogunu oluşturma`}</h2>
    <p>{`Action'ın karşılama mesajını özelleştirdiğinize göre, Action'ın diyalogunun kalanını oluşturmaya geçebiliriz. Bu çalışmaya devam etmeden önce, aşağıdaki terimlere aşina olmanız, Action'ın nasıl çalıştığını anlamanız için faydalı olacaktır. `}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Scene (Sahne):`}</strong>{` Action'ın kullanıcı girdisini işleyen ve genelde kullanıcıya cevap veren yapıtaşı.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`User Intent (Kullanıcının Amacı):`}</strong>{` Action tarafından anlaşılabilen kullanıcı girdilerini ifade eder. Intent'ler için `}<em parentName="p">{`örnek cümleleri`}</em>{` (training phrase) belirlersiniz. Kullanıcı, bu örnek cümlelerden birini veya buna yakın bir cümleyi söylediğinde intent eşleşmiş (match) olur.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Training phrases (Örnek Cümleler):`}</strong>{` Kullanıcının bir intent'i (amacı) ifade etmek için kullanabileceği örnek cümleler. Assistant'ın NLU (Natural Language Understanding - Doğal Dil İşleme) motoru, bu cümlelere yakın cümleleri de tanımak için training phrase'lerden öğrenir.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Transition (Geçiş):`}</strong>{` Koşullar gerçekleştiğinde hangi sahneden sahneye geçilmesi`}</p>
    </blockquote>
    <p>{`Action bir veya daha fazla sahneden oluşabilir. Sahnelerin çalışmaları için tetiklenmeleri gerekir. (Bu çalışmada geliştireceğiniz Action'ın `}<inlineCode parentName="p">{`Start`}</inlineCode>{` isimli tek bir sahnesi olacak) Bir sahneyi tetiklemenin en kolay yolu, Action'ın bir başka sahnesinde bir intent eşleştiğinde diğer sahneye geçilmesi için trasition tanımlamaktır.`}</p>
    <p>{`Örneğin,  kullanıcılara hayvanlarla ilgili bilgiler veren farazi bir Action olduğunu düşünelim. Kullanıcı bu Action'ı çağırdığında, `}<inlineCode parentName="p">{`Main invocation`}</inlineCode>{`  intent'i eşleşir ve `}<inlineCode parentName="p">{`Facts`}</inlineCode>{` isimli bir sahneye geçişi tetikler. Bu geçiş Facts isimli sahneyi tetikler ve o sahne de kullaınıcıya `}<inlineCode parentName="p">{`"Kedilerle ilgili mi yoksa köpeklerle ilgili mi bilgi almak istersiniz?"`}</inlineCode>{` diye bir cevap döner. `}<inlineCode parentName="p">{`Facts`}</inlineCode>{` sahnesi içerisinde kullanıcının kedi ile ilgili bilgi almak istediğinde söyleyebileceği bazı training phrase'ler ile tanımlanmış `}<inlineCode parentName="p">{`Cat`}</inlineCode>{` isminde bir intent vardır. Kullanıcı kedilerle ile ilgili bilgi almak istediğini söylediğinde `}<inlineCode parentName="p">{`Cat`}</inlineCode>{` intent'i eşleşir ve `}<inlineCode parentName="p">{`Cat fact`}</inlineCode>{` isimli bir başka sahneye geçişi tetikler.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "659px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.42857142857143%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAEDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdghLwP/xAAYEAEBAAMAAAAAAAAAAAAAAAABAhIhMf/aAAgBAQABBQKmpW8U0UWtRVJz/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAQQDAAAAAAAAAAAAAAAAEQABAhASIoH/2gAIAQEABj8C1geoYPWshxHN2r//xAAbEAADAQADAQAAAAAAAAAAAAAAAREhMUFRYf/aAAgBAQABPyGcne0c8e8QqgjDS/RFF/Eh6P/aAAwDAQACAAMAAAAQKA//xAAXEQEBAQEAAAAAAAAAAAAAAAABIRAR/9oACAEDAQE/EHrCZ//EABcRAQEBAQAAAAAAAAAAAAAAAAEhEBH/2gAIAQIBAT8QOFbn/8QAGhABAQADAQEAAAAAAAAAAAAAAREAITFBgf/aAAgBAQABPxCudyAN3m8cDWbWK+ZEKFWfcsDtEtu9woNESRR7g2KbTZHuf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Actions Builder ile geliştirilmiş bir Action'daki bir konuşma turu",
            "title": "Actions Builder ile geliştirilmiş bir Action'daki bir konuşma turu",
            "src": "/static/870fd53a1d16726f4e5282d6d64beb33/fff7d/diagram.jpg",
            "srcSet": ["/static/870fd53a1d16726f4e5282d6d64beb33/60342/diagram.jpg 210w", "/static/870fd53a1d16726f4e5282d6d64beb33/65f94/diagram.jpg 420w", "/static/870fd53a1d16726f4e5282d6d64beb33/fff7d/diagram.jpg 659w"],
            "sizes": "(max-width: 659px) 100vw, 659px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Actions Builder ile geliştirilmiş bir Action'daki bir konuşma turu`}</figcaption>{`
  `}</figure></p>
    <p>{`Sahneler, intentler ve geçişler bir araya gelerek Action'ın mantığını oluştururlar ve kullanıcının Action içerisinde gidebileceği farklı yolları belirlerler. Bir sonraki bölümde bir sahne oluşturmayı ve kullanıcı uygulamanızı çağırdığında bu sahnenin nasıl tetikleneceğini öğreneceksiniz.`}</p>
    <h3>{`Main invocation'dan sahneye geçiş`}</h3>
    <p>{`Bu bölümde kullanıcıya falına bakılmasını isteyip istemediğini soran `}<inlineCode parentName="p">{`Start`}</inlineCode>{` isminde bir sahne oluşturacaksınız. Aynı zamanda main invocation'dan `}<inlineCode parentName="p">{`Start`}</inlineCode>{` sahnesine bir geçiş oluşturacaksınız.`}</p>
    <p>{`Bu sahneyi oluşturmak ve geçiş eklemek için aşağıdaki adımları takip edin:`}</p>
    <ol>
      <li parentName="ol">{`Üst menüden `}<strong parentName="li">{`Develop`}</strong>{` sekmesine gelin. Daha sonra sol menüden main invocation’ı seçin.`}</li>
      <li parentName="ol">{`Sağdaki Transition (geçiş) bölümünde yer alan açılır menüye tıklayın ve kutucuğa `}<inlineCode parentName="li">{`Start`}</inlineCode>{` yazın
`}<figure parentName="li" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "840px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "39.04761904761905%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB3aEAf//EABYQAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAQABBQJJn//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABkQAQACAwAAAAAAAAAAAAAAAAEAERAxgf/aAAgBAQABPyFFjfIax//aAAwDAQACAAMAAAAQd8//xAAWEQEBAQAAAAAAAAAAAAAAAAARECH/2gAIAQMBAT8QdJ//xAAXEQEAAwAAAAAAAAAAAAAAAAABEBEh/9oACAECAQE/EEy4/8QAGxABAAEFAQAAAAAAAAAAAAAAAUEAEBEhUZH/2gAIAQEAAT8QQaBAV8KWQk9t/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Transition oluşturma",
              "title": "Transition oluşturma",
              "src": "/static/7805ca74d7bd0c51437171354a33157a/bba1f/6.jpg",
              "srcSet": ["/static/7805ca74d7bd0c51437171354a33157a/60342/6.jpg 210w", "/static/7805ca74d7bd0c51437171354a33157a/65f94/6.jpg 420w", "/static/7805ca74d7bd0c51437171354a33157a/bba1f/6.jpg 840w", "/static/7805ca74d7bd0c51437171354a33157a/3f513/6.jpg 1096w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Transition oluşturma`}</figcaption>{`
  `}</figure></li>
      <li parentName="ol"><strong parentName="li">{`Add`}</strong>{`’e tıklayın. Böylece `}<inlineCode parentName="li">{`Start`}</inlineCode>{` isminde bir sahne oluşturmuş ve Action'ı kullanıcıya karşılama mesajını verdikten sonra `}<inlineCode parentName="li">{`Start`}</inlineCode>{` sahnesine geçecek şekilde ayarlamış oldunuz`}</li>
      <li parentName="ol">{`Sahnelerinizin listesini görmek için sol menüden `}<strong parentName="li">{`Scenes`}</strong>{`’e tıklayın.`}</li>
      <li parentName="ol"><strong parentName="li">{`Scenes`}</strong>{`’in altında `}<inlineCode parentName="li">{`Start`}</inlineCode>{` sahnesini görmek için `}<strong parentName="li">{`Start`}</strong>{`’a basın.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Start`}</inlineCode>{` sahnesinin `}<strong parentName="li">{`On enter (giriş yapılınca)`}</strong>{` bölümünde yer alan `}<strong parentName="li">{`+`}</strong>{` tuşuna basın`}</li>
      <li parentName="ol">{`Sağda `}<strong parentName="li">{`Send prompts`}</strong>{`’u `}<strong parentName="li">{`(cevap gönder)`}</strong>{` işaretleyin`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`speech`}</inlineCode>{` (konuşma) alanındaki cevap cümlesini kullanıcıya sormak istediğiniz soru ile değiştirin: `}<inlineCode parentName="li">{`Macerana devam etmeden önce falına bakılmasını ister misin?`}</inlineCode>
        <figure parentName="li" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "840px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "37.14285714285714%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3gUH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAQABPyGYj//aAAwDAQACAAMAAAAQcA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBX/8QAFxABAQEBAAAAAAAAAAAAAAAAAQBxIf/aAAgBAQABPxAtZbqDpf/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Start sahnesi",
              "title": "Start sahnesi",
              "src": "/static/c6efe944ea37a5b9f4031582c1965796/bba1f/7.jpg",
              "srcSet": ["/static/c6efe944ea37a5b9f4031582c1965796/60342/7.jpg 210w", "/static/c6efe944ea37a5b9f4031582c1965796/65f94/7.jpg 420w", "/static/c6efe944ea37a5b9f4031582c1965796/bba1f/7.jpg 840w", "/static/c6efe944ea37a5b9f4031582c1965796/3ac88/7.jpg 1260w", "/static/c6efe944ea37a5b9f4031582c1965796/ba579/7.jpg 1680w", "/static/c6efe944ea37a5b9f4031582c1965796/1246e/7.jpg 3406w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Start sahnesi`}</figcaption>{`
  `}</figure></li>
      <li parentName="ol"><strong parentName="li">{`Save`}</strong>{`’e tıklayarak kaydedin.`}</li>
    </ol>
    <p>{`Artık Google Asistan, kullanıcı sahneye girdiğinde yazdığınız soruyu soracaktır.`}</p>
    <h3>{`Öneri butonları ekleyin`}</h3>
    <p>{`Suggestion chips (öneri butonları) kullanıcıların sesle girdi yapmak yerine üzerine tıklayabilecekleri öneriler sunan araçlardır. Bu bölümde, ekranı olan bir cihazda Action'ı kullanan kullanıcılara sorulan soruya cevap olarak verilebilecek seçenekleri öneri butonu olarak göstermeyi öğreneceksiniz.`}</p>
    <p><inlineCode parentName="p">{`Start`}</inlineCode>{` sahnesinin sorusuna öneri butonu eklemek için aşağıdaki adımları takip edin:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`Start`}</inlineCode>{` sahnesinde, kod editörünün altındaki `}<strong parentName="li">{`suggestions`}</strong>{`’a basın. Bu işlem bir adet öneri butonu oluşturacaktır`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`title`}</inlineCode>{` alanında, Suggested Response (önerilen cevap) kısmını `}<inlineCode parentName="li">{`'Evet'`}</inlineCode>{` ile değiştirin`}</li>
      <li parentName="ol">{`Aynı formatı kullanarak `}<inlineCode parentName="li">{`'Hayır'`}</inlineCode>{` için de bir öneri butonu ekleyin. kodunuz aşağıdaki gibi gözükmelidir.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{` suggestions:
      - title: 'Evet'
      - title: 'Hayır'
`}</code></pre>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Save`}</strong>{`’e tıklayarak kaydedin.`}</li>
    </ol>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "840px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.14285714285714%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAG8AD//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAEFAn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAXEAEAAwAAAAAAAAAAAAAAAAABABAR/9oACAEBAAE/IawZ/9oADAMBAAIAAwAAABADz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EFf/xAAYEAEBAAMAAAAAAAAAAAAAAAABACFhcf/aAAgBAQABPxAus5CC7L//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Start sahnesi",
            "title": "Start sahnesi",
            "src": "/static/861cc808de2bf941e13e16d82a0b8167/bba1f/8.jpg",
            "srcSet": ["/static/861cc808de2bf941e13e16d82a0b8167/60342/8.jpg 210w", "/static/861cc808de2bf941e13e16d82a0b8167/65f94/8.jpg 420w", "/static/861cc808de2bf941e13e16d82a0b8167/bba1f/8.jpg 840w", "/static/861cc808de2bf941e13e16d82a0b8167/3ac88/8.jpg 1260w", "/static/861cc808de2bf941e13e16d82a0b8167/ba579/8.jpg 1680w", "/static/861cc808de2bf941e13e16d82a0b8167/bb549/8.jpg 3418w"],
            "sizes": "(max-width: 840px) 100vw, 840px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Start sahnesi`}</figcaption>{`
  `}</figure></p>
    <h3>{`Action’ı Simülatörde test edin`}</h3>
    <p>{`Bu aşamada Action main invocation'dan `}<inlineCode parentName="p">{`Start`}</inlineCode>{` sahnesine geçiş yapmalı ve kullanıcıya falına bakılmasını isteyip istemediğini sorabilmeli. Aynı zamanda simülatör ekranında öneri butonlarını da görebiliyor olmalıyız. `}</p>
    <p>{`Action'ı simülatörü test etmek için aşağıdaki adımları izleyin:`}</p>
    <ol>
      <li parentName="ol">{`Üst menüden `}<strong parentName="li">{`Test`}</strong>{` sekmesine gelin`}</li>
      <li parentName="ol">{`Action'ı simülatörde test etmek için `}<strong parentName="li">{`Input`}</strong>{` kutucuğuna `}<inlineCode parentName="li">{`Test uygulamam ile konuş`}</inlineCode>{` yazın ve `}<strong parentName="li">{`Enter`}</strong>{`’a basın.`}</li>
      <li parentName="ol">{`Action, `}<inlineCode parentName="li">{`main invocation`}</inlineCode>{` cevabı ile cevap vermeli daha sonra `}<inlineCode parentName="li">{`Start`}</inlineCode>{` sahnesi için belirlediğiniz soruyu sormalıdır: `}<inlineCode parentName="li">{`Macerana devam etmeden önce falına bakılmasını ister misin?`}</inlineCode>{`
Etkileşim şu şekilde olmalıdır:`}</li>
    </ol>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "840px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.714285714285715%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHcstoEh//EABYQAAMAAAAAAAAAAAAAAAAAAAERIP/aAAgBAQABBQJmv//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABgQAAMBAQAAAAAAAAAAAAAAAAEQMQAh/9oACAEBAAE/IekZuEX/2gAMAwEAAgADAAAAEGAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAAICAwAAAAAAAAAAAAAAAQARMXEQUWH/2gAIAQEAAT8QQQXO7haSvCZtzHrj/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Öneri butonlarının testi",
            "title": "Öneri butonlarının testi",
            "src": "/static/d079786e0783cbcf6bfecac5e25a18fb/bba1f/9.jpg",
            "srcSet": ["/static/d079786e0783cbcf6bfecac5e25a18fb/60342/9.jpg 210w", "/static/d079786e0783cbcf6bfecac5e25a18fb/65f94/9.jpg 420w", "/static/d079786e0783cbcf6bfecac5e25a18fb/bba1f/9.jpg 840w", "/static/d079786e0783cbcf6bfecac5e25a18fb/3ac88/9.jpg 1260w", "/static/d079786e0783cbcf6bfecac5e25a18fb/ba579/9.jpg 1680w", "/static/d079786e0783cbcf6bfecac5e25a18fb/ccb49/9.jpg 3832w"],
            "sizes": "(max-width: 840px) 100vw, 840px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Öneri butonlarının testi`}</figcaption>{`
  `}</figure></p>
    <blockquote>
      <p parentName="blockquote">{`Event log'ları güncel oturumunuz için simülatördeki tüm etkileşimlerinizi göstermektedir. Dolayısıyla bu çalışmada yaptığınız önceki test de event log üzerinde görülmektedir. Event log’larını temizlemek için `}<strong parentName="p">{`Reset Test`}</strong>{`’e tıklayabilirsiniz.`}</p>
    </blockquote>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Soru sorulduğunda cevap vermek için `}<inlineCode parentName="li">{`Evet`}</inlineCode>{` veya `}<inlineCode parentName="li">{`Hayır`}</inlineCode>{` öneri butonlarından birine tıklayın (Dilerseniz mikrofonu kullanarak "Evet" veya "Hayır" diyebilir yahut `}<strong parentName="li">{`Input`}</strong>{` kutucuğuna `}<inlineCode parentName="li">{`Evet`}</inlineCode>{` ya da `}<inlineCode parentName="li">{`Hayır`}</inlineCode>{` yazabilirsiniz)`}</li>
    </ol>
    <p>{`Soruya cevap verdiğinizde Action size ne söylediğinizi anlamadığını söyleyerek cevap verecektir. Çünkü henüz Action’ın seçeneklere nasıl cevap vereceğini belirlemediniz. Bu nedenle `}<inlineCode parentName="p">{`NO_MATCH`}</inlineCode>{` intent’i eşleşti.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`NO_MATCH:`}</strong>{` Kullanıcının söyledikleri Action içerisindeki herhangi bir intent ile eşleşmediğinde devreye giren bir kapsayıcı intent’tir.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`System intent:`}</strong>{` Varsayılan olarak ayarlanmış olan genel geçer sistem olaylarını karşılayan intent’lerdir.  (Örneğin kullanıcının anlaşılmayan bir şey söylemesi gibi) `}<inlineCode parentName="p">{`NO_MATCH`}</inlineCode>{` ve main invocation'ı çağıran intent system intent’lerin örnekleridirler.`}</p>
    </blockquote>
    <p>{`Varsayılan olarak `}<inlineCode parentName="p">{`NO_MATCH`}</inlineCode>{` sistem intent’i genel bir cevap vermektedir. Ancak bu cevabı özelleştirebilirsiniz. Asistan eğer üç kez kullanıcının ne söylediğini anlayamaz ise Action ve kullanıcı arasındaki diyaloğu bitirecektir.`}</p>
    <h3>{`Evet ve Hayır intent’lerini ekleyin`}</h3>
    <p>{`Artık kullanıcılar Action’ın sorduğu soruya yanıt verebildiğine göre, Action’ı da kullanıcının yanıtını anlayacak şekilde geliştirmeliyiz. Bu bölümde kullanıcı 'Evet' veya 'Hayır' dediğinde tetiklenecek olan intent’leri oluşturulmasını ve bunları `}<inlineCode parentName="p">{`Start`}</inlineCode>{` sahnesine eklenmesini öğreneceğiz.`}</p>
    <p><inlineCode parentName="p">{`yes`}</inlineCode>{` `}<strong parentName="p">{`intent’ini oluşturun`}</strong></p>
    <p>{`Evet intent’ini oluşturmak için aşağıdaki adımları takip edin:`}</p>
    <ol>
      <li parentName="ol">{`Üst menüden `}<strong parentName="li">{`Develop`}</strong>{` sekmesine gelin`}</li>
      <li parentName="ol">{`Soldaki menüden `}<strong parentName="li">{`Intents`}</strong>{`’e tıklayarak intent’lerin listesini açın`}</li>
      <li parentName="ol">{`Intent listesinin sonundaki `}<strong parentName="li">{`+`}</strong>{`‘ya basın. Yeni intent’in adını `}<inlineCode parentName="li">{`yes`}</inlineCode>{` koyun ve `}<strong parentName="li">{`Enter`}</strong>{`’a basın`}</li>
      <li parentName="ol">{`Soldaki menüde `}<inlineCode parentName="li">{`yes`}</inlineCode>{`’e tıklayarak `}<inlineCode parentName="li">{`yes`}</inlineCode>{` intent’ini görüntüleyin`}</li>
      <li parentName="ol"><strong parentName="li">{`Add training phrases`}</strong>{` bölümünde, örnek cümleler eklemek için `}<strong parentName="li">{`Enter Phrase`}</strong>{` kutucuğuna aşağıdaki cümleleri ekleyin:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Evet`}</li>
      <li parentName="ul">{`E`}</li>
      <li parentName="ul">{`Olur`}</li>
      <li parentName="ul">{`İsterim`}</li>
      <li parentName="ul">{`Bak`}</li>
    </ul>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol"><strong parentName="li">{`Save`}</strong>{`’e basarak kaydedin`}</li>
    </ol>
    <p><inlineCode parentName="p">{`yes`}</inlineCode>{` `}<strong parentName="p">{`intent’ini `}<inlineCode parentName="strong">{`Start`}</inlineCode>{` sahnesine ekleyin`}</strong></p>
    <p>{`Artık Action kullanıcının `}<inlineCode parentName="p">{`yes`}</inlineCode>{` intent’ini tetiklediğini anlayabiliyor. Kullanıcı `}<inlineCode parentName="p">{`Start`}</inlineCode>{` sahnesinin sorusuna yanıt olarak "Evet" dediği için `}<inlineCode parentName="p">{`yes`}</inlineCode>{` intent’ini `}<inlineCode parentName="p">{`Start`}</inlineCode>{` sahnesine ekleyelim.`}</p>
    <p>{`Bu intent’i `}<inlineCode parentName="p">{`Start`}</inlineCode>{` sahnesinde eklemek için aşağıdaki adımları uygulayın`}</p>
    <ol>
      <li parentName="ol">{`Sol menüden S`}<strong parentName="li">{`cenes`}</strong>{`’in altında `}<inlineCode parentName="li">{`Start`}</inlineCode>{` sahnesini seçin`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Start`}</inlineCode>{` sahnesi altında yer alan `}<strong parentName="li">{`User intent handling`}</strong>{` kısmının yanındaki `}<strong parentName="li">{`+`}</strong>{`’ya tıklayın`}</li>
      <li parentName="ol">{`Açılan menüde `}<inlineCode parentName="li">{`yes`}</inlineCode>{` seçeneğini seçin
`}<figure parentName="li" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "840px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "53.333333333333336%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAG+AD//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAEFAl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAE/IV//2gAMAwEAAgADAAAAEHDP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGBABAQEBAQAAAAAAAAAAAAAAAQAREDH/2gAIAQEAAT8QDDi5HkTf/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Evet intent'i",
              "title": "Evet intent'i",
              "src": "/static/bf204ff3e384a6eec596ff0fcba72fd9/bba1f/10.jpg",
              "srcSet": ["/static/bf204ff3e384a6eec596ff0fcba72fd9/60342/10.jpg 210w", "/static/bf204ff3e384a6eec596ff0fcba72fd9/65f94/10.jpg 420w", "/static/bf204ff3e384a6eec596ff0fcba72fd9/bba1f/10.jpg 840w", "/static/bf204ff3e384a6eec596ff0fcba72fd9/3ac88/10.jpg 1260w", "/static/bf204ff3e384a6eec596ff0fcba72fd9/ba579/10.jpg 1680w", "/static/bf204ff3e384a6eec596ff0fcba72fd9/dfb08/10.jpg 2713w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Evet intent'i`}</figcaption>{`
  `}</figure></li>
      <li parentName="ol">{`Sağdaki bölümden `}<strong parentName="li">{`Send prompts`}</strong>{`’u işaretlerin ve `}<inlineCode parentName="li">{`speech`}</inlineCode>{` alanını `}<inlineCode parentName="li">{`Geleceğin, macera için seçeceğin yardıma bağlı. Seçimini akıllıca yap! Elveda, yabancı.`}</inlineCode>{` olarak değiştirin`}</li>
    </ol>
    <p>{`Editördeki kod şu şekilde gözükmeli:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`candidates:
  - first_simple:
      variants:
        - speech: >-
        Geleceğin, macera için seçeceğin yardıma bağlı. Seçimini akıllıca yap! Elveda, yabancı.
`}</code></pre>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol"><strong parentName="li">{`Transition`}</strong>{` bölümünde açılan menüden `}<strong parentName="li">{`End conversation`}</strong>{`’ı seçerek konuşmayı bitirin`}</li>
      <li parentName="ol"><strong parentName="li">{`Save`}</strong>{`’e tıklayarak kaydedin`}</li>
    </ol>
    <p><inlineCode parentName="p">{`**yes`}</inlineCode>{` intent’ini Simülatörde test edin**`}</p>
    <p>{`Bu aşamada Action kullanıcının falına bakılmasını istediğini anlıyor ve uygun bir cevap verebiliyor.`}</p>
    <p>{`Bu intent’i simülatörde test etmek için aşağıdaki adımları izleyin:`}</p>
    <ol>
      <li parentName="ol">{`Üst menüden `}<strong parentName="li">{`Test`}</strong>{` sekmesine gelin`}</li>
      <li parentName="ol">{`Action'ı simülatörde test etmek için `}<strong parentName="li">{`Input`}</strong>{` kutucuğuna `}<inlineCode parentName="li">{`Test uygulamam ile konuş`}</inlineCode>{` yazın ve `}<strong parentName="li">{`Enter`}</strong>{`’a basın.`}</li>
      <li parentName="ol"><strong parentName="li">{`Input`}</strong>{` kutucuğuna `}<inlineCode parentName="li">{`Evet`}</inlineCode>{` yazın veya öneri butonlarından `}<strong parentName="li">{`“Evet”`}</strong>{`i seçin
`}<figure parentName="li" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "840px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe7ZoA//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAEFAl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAWEAADAAAAAAAAAAAAAAAAAAAgIWH/2gAIAQEAAT8hcL//2gAMAwEAAgADAAAAEODP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBAAAgICAwAAAAAAAAAAAAAAAAERURAhQWFx/9oACAEBAAE/EF3h6bIVDSpC4x//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Evet intent'inin testi",
              "title": "Evet intent'inin testi",
              "src": "/static/47ccbeb5aea33e205b9eb0cd8b2f82c5/bba1f/11.jpg",
              "srcSet": ["/static/47ccbeb5aea33e205b9eb0cd8b2f82c5/60342/11.jpg 210w", "/static/47ccbeb5aea33e205b9eb0cd8b2f82c5/65f94/11.jpg 420w", "/static/47ccbeb5aea33e205b9eb0cd8b2f82c5/bba1f/11.jpg 840w", "/static/47ccbeb5aea33e205b9eb0cd8b2f82c5/3ac88/11.jpg 1260w", "/static/47ccbeb5aea33e205b9eb0cd8b2f82c5/ba579/11.jpg 1680w", "/static/47ccbeb5aea33e205b9eb0cd8b2f82c5/e6b01/11.jpg 3840w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Evet intent'inin testi`}</figcaption>{`
  `}</figure></li>
    </ol>
    <p>{`Gördüğünüz gibi artık Action kullanıcıya cevap veriyor ve geleceklerinin seçecekleri yardıma bağlı olduğunu söylüyor. Daha sonra ise `}<strong parentName="p">{`transition`}</strong>{` olarak `}<inlineCode parentName="p">{`End conversation`}</inlineCode>{` belirlediğimiz için Action konuşmayı bitiriyor.`}</p>
    <p><inlineCode parentName="p">{`no`}</inlineCode>{` `}<strong parentName="p">{`intent’ini oluşturun`}</strong></p>
    <p>{`Evet intent’ini oluşturmak için aşağıdaki adımları takip edin:`}</p>
    <ol>
      <li parentName="ol">{`Üst menüden `}<strong parentName="li">{`Develop`}</strong>{` sekmesine gelin`}</li>
      <li parentName="ol">{`Soldaki menüden `}<strong parentName="li">{`Intents`}</strong>{`’e tıklayarak intent’lerin listesini açın`}</li>
      <li parentName="ol">{`Intent listesinin sonundaki `}<strong parentName="li">{`+`}</strong>{`‘ya basın. Yeni intent’in adını `}<inlineCode parentName="li">{`no`}</inlineCode>{` koyun ve `}<strong parentName="li">{`Enter`}</strong>{`’a basın`}</li>
      <li parentName="ol">{`Soldaki menüde `}<inlineCode parentName="li">{`no`}</inlineCode>{`’ya tıklayarak `}<inlineCode parentName="li">{`no`}</inlineCode>{` intent’ini görüntüleyin`}</li>
      <li parentName="ol"><strong parentName="li">{`Add training phrases`}</strong>{` bölümünde, örnek cümleler eklemek için `}<strong parentName="li">{`Enter Phrase`}</strong>{` kutucuğuna aşağıdaki cümleleri ekleyin:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Hayor`}</li>
      <li parentName="ul">{`H`}</li>
      <li parentName="ul">{`Olmaz`}</li>
      <li parentName="ul">{`İstemrm`}</li>
      <li parentName="ul">{`Bakma`}</li>
    </ul>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol"><strong parentName="li">{`Save`}</strong>{`’e basarak kaydedin`}</li>
    </ol>
    <p><inlineCode parentName="p">{`no`}</inlineCode>{` `}<strong parentName="p">{`intent’ini `}<inlineCode parentName="strong">{`Start`}</inlineCode>{` sahnesine ekleyin`}</strong></p>
    <p>{`Artık Action kullanıcının `}<inlineCode parentName="p">{`no`}</inlineCode>{` intent’ini tetiklediğini anlayabiliyor. Kullanıcı `}<inlineCode parentName="p">{`Start`}</inlineCode>{` sahnesinin sorusuna yanıt olarak "Hayır" dediği için `}<inlineCode parentName="p">{`no`}</inlineCode>{` intent’ini `}<inlineCode parentName="p">{`Start`}</inlineCode>{` sahnesine ekleyelim.`}</p>
    <p>{`Bu intent’i `}<inlineCode parentName="p">{`Start`}</inlineCode>{` sahnesinde eklemek için aşağıdaki adımları uygulayın`}</p>
    <ol>
      <li parentName="ol">{`Sol menüden S`}<strong parentName="li">{`cenes`}</strong>{`’in altında `}<inlineCode parentName="li">{`Start`}</inlineCode>{` sahnesini seçin`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Start`}</inlineCode>{` sahnesi altında yer alan `}<strong parentName="li">{`User intent handling`}</strong>{` kısmının yanındaki `}<strong parentName="li">{`+`}</strong>{`’ya tıklayın`}</li>
      <li parentName="ol">{`Açılan menüde `}<inlineCode parentName="li">{`no`}</inlineCode>{` seçeneğini seçin
`}<figure parentName="li" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "840px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "37.61904761904762%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3qAH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABPyF//9oADAMBAAIAAwAAABAAD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABgQAAMBAQAAAAAAAAAAAAAAAAABETGR/9oACAEBAAE/EFZq6Kwl0//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Hayır intent'i",
              "title": "Hayır intent'i",
              "src": "/static/27627611a60f71c0bf2cd7596595b869/bba1f/12.jpg",
              "srcSet": ["/static/27627611a60f71c0bf2cd7596595b869/60342/12.jpg 210w", "/static/27627611a60f71c0bf2cd7596595b869/65f94/12.jpg 420w", "/static/27627611a60f71c0bf2cd7596595b869/bba1f/12.jpg 840w", "/static/27627611a60f71c0bf2cd7596595b869/c60c2/12.jpg 1117w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Hayır intent'i`}</figcaption>{`
  `}</figure></li>
      <li parentName="ol">{`Sağdaki bölümden `}<strong parentName="li">{`Send prompts`}</strong>{`’u işaretlerin ve `}<inlineCode parentName="li">{`speech`}</inlineCode>{` alanını `}<inlineCode parentName="li">{`Anlıyorum yabancı. Maceranda bol şans! Elveda.`}</inlineCode>{` olarak değiştirin`}</li>
    </ol>
    <p>{`Editördeki kod şu şekilde gözükmeli:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`candidates:
  - first_simple:
      variants:
        - speech: >-
            Anlıyorum yabancı. Maceranda bol şans! Elveda.
`}</code></pre>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol"><strong parentName="li">{`Transition`}</strong>{` bölümünde açılan menüden `}<strong parentName="li">{`End conversation`}</strong>{`’ı seçerek konuşmayı bitirin`}</li>
      <li parentName="ol"><strong parentName="li">{`Save`}</strong>{`’e tıklayarak kaydedin`}</li>
    </ol>
    <p><inlineCode parentName="p">{`no`}</inlineCode>{` `}<strong parentName="p">{`intent’ini Simülatörde test edin`}</strong></p>
    <p>{`Bu aşamada Action kullanıcının falına bakılmasını istediğini anlıyor ve uygun bir cevap verebiliyor.`}</p>
    <p>{`Bu intent’i simülatörde test etmek için aşağıdaki adımları izleyin:`}</p>
    <ol>
      <li parentName="ol">{`Üst menüden `}<strong parentName="li">{`Test`}</strong>{` sekmesine gelin`}</li>
      <li parentName="ol">{`Action'ı simülatörde test etmek için `}<strong parentName="li">{`Input`}</strong>{` kutucuğuna `}<inlineCode parentName="li">{`Test uygulamam ile konuş`}</inlineCode>{` yazın ve `}<strong parentName="li">{`Enter`}</strong>{`’a basın.`}</li>
      <li parentName="ol"><strong parentName="li">{`Input`}</strong>{` kutucuğuna `}<inlineCode parentName="li">{`Hayır`}</inlineCode>{` yazın veya öneri butonlarından `}<strong parentName="li">{`“Hayır”`}</strong>{`ı seçin
`}<figure parentName="li" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "840px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "47.61904761904761%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAd5xYDD/xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAEFAl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAXEAADAQAAAAAAAAAAAAAAAAAQESAh/9oACAEBAAE/IdCj/9oADAMBAAIAAwAAABCwD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAQACAwEAAAAAAAAAAAAAAAEAERAxQVH/2gAIAQEAAT8QDpIFkpKPCGjH/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Hayır intent'inin testi",
              "title": "Hayır intent'inin testi",
              "src": "/static/8b559232cba7e14b0568eca17c6e72b8/bba1f/13.jpg",
              "srcSet": ["/static/8b559232cba7e14b0568eca17c6e72b8/60342/13.jpg 210w", "/static/8b559232cba7e14b0568eca17c6e72b8/65f94/13.jpg 420w", "/static/8b559232cba7e14b0568eca17c6e72b8/bba1f/13.jpg 840w", "/static/8b559232cba7e14b0568eca17c6e72b8/3ac88/13.jpg 1260w", "/static/8b559232cba7e14b0568eca17c6e72b8/ba579/13.jpg 1680w", "/static/8b559232cba7e14b0568eca17c6e72b8/2dc34/13.jpg 3829w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Hayır intent'inin testi`}</figcaption>{`
  `}</figure>{`
Gördüğünüz gibi artık Action kullanıcının falına bakmak yerine macerasında ona başarılar diliyor. Daha sonra ise `}<strong parentName="li">{`transition`}</strong>{` olarak `}<inlineCode parentName="li">{`End conversation`}</inlineCode>{` belirlediğimiz için Action konuşmayı bitiriyor.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      