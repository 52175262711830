// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-templates-blog-list-by-tag-template-js": () => import("./../../../src/templates/blogListByTagTemplate.js" /* webpackChunkName: "component---src-templates-blog-list-by-tag-template-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blogListTemplate.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-workshop-list-template-js": () => import("./../../../src/templates/workshopListTemplate.js" /* webpackChunkName: "component---src-templates-workshop-list-template-js" */),
  "component---src-templates-workshop-template-js": () => import("./../../../src/templates/workshopTemplate.js" /* webpackChunkName: "component---src-templates-workshop-template-js" */)
}

