import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Giriş`}</h2>
    <p>{`Google Assistant üzerinde kendi sesli uygulamalarınızı, yani Action'larınızı, yapmak ve bunları yayına almak konusunda hiç tecrübeniz olmasa bile bu atölye çalışmasını tamamlayarak platformun temel çalışma mantığını kavrayabilir, konsola aşina olabilir ve öğrendiklerinizi kendi sesli uygulamalarınızı geliştirmek için kullanabilirsiniz.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Bu çalışma, Google Assistant Developer Relations tarafından yazılan `}<a parentName="p" {...{
          "href": "https://codelabs.developers.google.com/codelabs/actions-builder-1?hl=en#0"
        }}>{`Build Actions for Google Assistant using Actions Builder (Level 1)`}</a>{` isimli codelab'in çevirisidir.`}</p>
    </blockquote>
    <h3>{`Geliştirecekleriniz`}</h3>
    <p>{`Bu çalışmanın sonunda:`}</p>
    <ul>
      <li parentName="ul">{`Uygulamanıza gelen kullanıcılara için hoşgeldin mesajı oluşturmayı,`}</li>
      <li parentName="ul">{`Kullanıcılara sorular sormayı,`}</li>
      <li parentName="ul">{`Kullanıcının verdiği cevaba göre uygulamanızın vereceği cevabı özelleştirmeyi,`}</li>
      <li parentName="ul">{`Kullanıcının sorunuza hızlıca yanıt verebilmeleri için öneri butonları koymayı,`}</li>
      <li parentName="ul">{`Kullanıcı uygulamanızı ilk kez kullanmıyorsa, uygulamanın karşılama mesajını özelleştirmeyi`}</li>
    </ul>
    <p>{`öğreneceksiniz.`}</p>
    <p>{`Bu çalışmayı bitirdiğinizde, geliştirdiğiniz Action aşağıdaki gibi çalışacak`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "793px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "123.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAQACBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe8IaqA3BMf/xAAWEAEBAQAAAAAAAAAAAAAAAAAhADD/2gAIAQEAAQUCZz//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ak//xAAcEAABBQADAAAAAAAAAAAAAABRAAEQESEgcYH/2gAIAQEAAT8hcF4h+6WG4egsHH//2gAMAwEAAgADAAAAEEAEMP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB8QAAMAAQMFAAAAAAAAAAAAAAABESExQZEgUWFxgf/aAAgBAQABPxDIoU8kut8haZJ3dvYnUr6FothKrozwl4Eou3R//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Geliştireceğiniz Action'ın konuşma akışı",
            "title": "Geliştireceğiniz Action'ın konuşma akışı",
            "src": "/static/3f0ebfa71ed22ecd4b64836452e0465a/50376/16.jpg",
            "srcSet": ["/static/3f0ebfa71ed22ecd4b64836452e0465a/60342/16.jpg 210w", "/static/3f0ebfa71ed22ecd4b64836452e0465a/65f94/16.jpg 420w", "/static/3f0ebfa71ed22ecd4b64836452e0465a/50376/16.jpg 793w"],
            "sizes": "(max-width: 793px) 100vw, 793px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Geliştireceğiniz Action'ın konuşma akışı`}</figcaption>{`
  `}</figure></p>
    <h3>{`Öğrenecekleriniz`}</h3>
    <p>{`Bu çalışmanın sonunda:`}</p>
    <ul>
      <li parentName="ul">{`Actions konsolda proje oluşturmayı`}</li>
      <li parentName="ul">{`Kullanıcı uygulamanıza geldiğinde onu karşılamayı`}</li>
      <li parentName="ul">{`Kullanıcının söylediklerini anlamayı ve cevaplamayı`}</li>
      <li parentName="ul">{`Uygulamanızı Actions Simulator'de test etmeyi`}</li>
      <li parentName="ul">{`Uygulamanızın fulfillment'ını (mantığını) Cloud Functions kullanarak geliştirmeyi`}</li>
    </ul>
    <p>{`öğreneceksiniz.`}</p>
    <h3>{`Gerekenler`}</h3>
    <ul>
      <li parentName="ul">{`Bir web tarayıcı`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      