import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Fulfillment geliştirin`}</h2>
    <p>{`Şimdilik sesli uygulamanız yalnızca statik cevaplar verebiliyor. Diğer bir ifade ile kullanıcı aynı sahneyi tetiklediğinde hep aynı cevap alıyor. Bu bölümde dinamik bir cevap oluşturmanızı sağlayacak bir mantığı nasıl geliştireceğinizi öğreneceksiniz.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Fulfillment:`}</strong>{` Action’ın çalışma mantığını içeren kod. Bir webhook sayesinde Action’da gerçekleşen olaylara göre fulfillment’ınıza çağrı yapabilirsiniz.`}</p>
    </blockquote>
    <p>{`Fulfillment’ınız kullanıcının yeni bir kullanıcı mı yoksa uygulamanıza geri dönen bir kullanıcı mı olduğunu algılayabilir ve buna göre uygulamanıza ilk kez gelmeyen kullanıcılara onlara özgü bir karşılama mesajı vermenizi sağlayabilir. Özelleştirilmiş karşılama mesajı, geri dönen kullanıcılar için daha kısa olacaktır ve kullanıcının yeni değil, geri dönen bir kullanıcı olduğunu belli edecektir: `}<em parentName="p">{`“Merhaba maceracı! Gryffinber’in sihirli dünyasına tekrar hoşgeldin!”`}</em></p>
    <p>{`Bu çalışmada fulfillment geliştirmek ve yayına almak için Actions konsolda yer alan Cloud Functions editörünü kullanacağız.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Cloud Functions editör:`}</strong>{` Actions konsol içerisinde yer alan ve `}<a parentName="p" {...{
          "href": "https://firebase.google.com/docs/functions/"
        }}>{`Firebase Cloud Functions`}</a>{` kullanarak fulfillment kodunuzu yazmanızı ve yayına almanızı sağlayan bir editör.`}</p>
    </blockquote>
    <p>{`Uygulamanız; çağrıldığında veya sahnelerin bazı bölümlerine gelindiğinde fulfillment’ınıza bazı event’lerin gerçekleştiğini bir webhook’u tetiklemek suretiyle bildirebilir. Bir webhook tetiklendiğinde, Action’ınız fulfillment’ınıza bir JSON payload ile gerçekleşen event’in adını içeren bir request gönderir. Fulfillment’ınızda bu event için geliştirdiğiniz handler mantığı çalışır ve kullanıcıya vermek istediğiniz cevabı içeren bir JSON payload ile cevap verir.`}</p>
    <h3>{`Fulfillment’ı geliştirin`}</h3>
    <p>{`Artık fulfillment’ınızı editörde yeni kullanıcılara ve geri dönen kullanıcılara farklı cevaplar vermek üzere geliştirebilirsiniz.`}</p>
    <p>{`Fulfillment’ınıza bu mantığı eklemek için aşağıdaki adımları takip edin:`}</p>
    <ol>
      <li parentName="ol">{`Üst menüden `}<strong parentName="li">{`Develop`}</strong>{` sekmesine gelin`}</li>
      <li parentName="ol">{`Soldaki menüden `}<strong parentName="li">{`Webhook`}</strong>{`’u seçin`}</li>
      <li parentName="ol"><strong parentName="li">{`Inline Cloud Functions`}</strong>{`’ı seçin ve `}<strong parentName="li">{`Confirm`}</strong>{`’e basarak onaylayın. `}<inlineCode parentName="li">{`index.js`}</inlineCode>{` ve `}<inlineCode parentName="li">{`package.json`}</inlineCode>{` içerisine şablon kodlar otomatik olarak eklenecektir.
`}<figure parentName="li" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "840px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "52.38095238095239%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAAB7dglgVP/xAAXEAADAQAAAAAAAAAAAAAAAAAAAQIg/9oACAEBAAEFAswz/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BV//EABURAQEAAAAAAAAAAAAAAAAAAAAS/9oACAECAQE/AUv/xAAXEAADAQAAAAAAAAAAAAAAAAAAASBx/9oACAEBAAY/Apen/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAERIEEx/9oACAEBAAE/IdJozb6P/9oADAMBAAIAAwAAABD4/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QTb//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAECAQE/EBw9P//EABwQAQABBAMAAAAAAAAAAAAAAAEAESExYUFxkf/aAAgBAQABPxAtyxuA35C8MHUViSpt0//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Webhook geliştirme türü",
              "title": "Webhook geliştirme türü",
              "src": "/static/e817322f5cca251f46f9acaf491d5b09/bba1f/14.jpg",
              "srcSet": ["/static/e817322f5cca251f46f9acaf491d5b09/60342/14.jpg 210w", "/static/e817322f5cca251f46f9acaf491d5b09/65f94/14.jpg 420w", "/static/e817322f5cca251f46f9acaf491d5b09/bba1f/14.jpg 840w", "/static/e817322f5cca251f46f9acaf491d5b09/3bcce/14.jpg 967w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Webhook geliştirme türü`}</figcaption>{`
  `}</figure></li>
      <li parentName="ol"><inlineCode parentName="li">{`index.js`}</inlineCode>{`’in içeriğini aşağıdaki kodla değiştirin:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { conversation } = require('@assistant/conversation');
const functions = require('firebase-functions');

const app = conversation({debug: true});

app.handle('greeting', conv => {
 let message = 'Merhaba maceracı! Gryffinber’in sihirli dünyasına tekrar hoşgeldin!';
 if (!conv.user.lastSeenTime) {
   message = 'Selam olsun maceracı! Efsanevi Gryffinberg\\'e hoşgeldin. Kıyafetlerine bakılırsa buralı değilsin. Destansı bir yolculuğa çıkmışsın.';
 }
 conv.add(message);
});

exports.ActionsOnGoogleFulfillment = functions.https.onRequest(app);
`}</code></pre>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol"><strong parentName="li">{`Save fulfillment`}</strong>{`’a basarak kaydedin.`}</li>
      <li parentName="ol"><strong parentName="li">{`Deploy fulfillement`}</strong>{`’a basarak yayına alın.`}</li>
    </ol>
    <p>{`Cloud Functions’ın kodunuzu yayına alması için birkaç dakika bekleyin. Beklerken editörün üstünde "`}<strong parentName="p">{`Cloud Function deployment in progress...`}</strong>{`" mesajını göreceksiniz. Kod yayına alındığında ise bu mesaj "`}<strong parentName="p">{`Your Cloud Function deployment is up to date."`}</strong>{` Olarak değişecektir.`}</p>
    <p>{`Fulfillment, `}<a parentName="p" {...{
        "href": "https://github.com/actions-on-google/assistant-conversation-nodejs"
      }}>{`Node.js Actions on Google Fulfillment kütüphanesini`}</a>{` kullanarak Asistan’dan gelen HTTP request’lerini cevaplıyor.`}</p>
    <p>{`Yukarıdaki kodda, `}<inlineCode parentName="p">{`greeting`}</inlineCode>{` isminde bir event handler tanımladınız. Bu handler fonksiyon, `}<inlineCode parentName="p">{`lastSeenTime`}</inlineCode>{` property’sine bakarak kullanıcının daha önce Action’ı kullanıp kullanmadığını kontrol ediyor. Eğer `}<inlineCode parentName="p">{`lastSeenTime`}</inlineCode>{` tanımlanmadıysa, kullanıcının yeni bir kullanıcı olduğunu anlıyor ve ilk kez gelen kullanıcılar için özelleştirilmiş olan karşılama mesajını veriyor.`}</p>
    <h3>{`Main invocation’ı bir webhook’u tetiklemek üzere değiştirin`}</h3>
    <p>{`Artık greeting fonksiyonunu tanımladığınıza göre, main invocation’ı bu handler fonksiyonu çağıracak şekilde ayarlayabilirsiniz.`}</p>
    <p>{`Action’ı `}<inlineCode parentName="p">{`greeting`}</inlineCode>{` handler’ını çağırmak üzere ayarlamak için aşağıdaki adımları takip edin:`}</p>
    <ol>
      <li parentName="ol">{`Soldaki menüden `}<strong parentName="li">{`Main invocation`}</strong>{`’ı seçin`}</li>
      <li parentName="ol"><strong parentName="li">{`Send prompts`}</strong>{`’tan işareti kaldırın ve `}<strong parentName="li">{`Call your webhook`}</strong>{`’u işaretleyin`}</li>
      <li parentName="ol">{`Kutucuğa `}<inlineCode parentName="li">{`greeting`}</inlineCode>{` yazın
`}<figure parentName="li" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "840px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "41.42857142857143%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3gUH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABPyF//9oADAMBAAIAAwAAABBzz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABcQAAMBAAAAAAAAAAAAAAAAAAABIRD/2gAIAQEAAT8QUFXn/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Webhook handle'ını girmek",
              "title": "Webhook handle'ını girmek",
              "src": "/static/762929afe93e5b481ef85bc72278bb54/bba1f/15.jpg",
              "srcSet": ["/static/762929afe93e5b481ef85bc72278bb54/60342/15.jpg 210w", "/static/762929afe93e5b481ef85bc72278bb54/65f94/15.jpg 420w", "/static/762929afe93e5b481ef85bc72278bb54/bba1f/15.jpg 840w", "/static/762929afe93e5b481ef85bc72278bb54/3ac88/15.jpg 1260w", "/static/762929afe93e5b481ef85bc72278bb54/ba579/15.jpg 1680w", "/static/762929afe93e5b481ef85bc72278bb54/4392e/15.jpg 3417w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Webhook handle'ını girmek`}</figcaption>{`
  `}</figure></li>
      <li parentName="ol"><strong parentName="li">{`Save`}</strong>{`’e basarak kaydedin`}</li>
    </ol>
    <h3>{`Main invocation’ın son halini simülatörde test edin`}</h3>
    <p>{`Action’ı simülatörde test etmek için aşağıdaki adımları izleyin:`}</p>
    <ol>
      <li parentName="ol">{`Üstteki menüden `}<strong parentName="li">{`Test`}</strong>{` sekmesine gelin`}</li>
      <li parentName="ol">{`Action’ı simülatörde test etmek için kutucuğa `}<inlineCode parentName="li">{`Test uygulamam ile konuş`}</inlineCode>{` yazın ve `}<inlineCode parentName="li">{`Enter`}</inlineCode>{`’a basın`}</li>
    </ol>
    <p>{`Bu çalışmada Action’ı daha evvel test ettiğiniz için yeni bir kullanıcı değilsiniz. Bu yüzden kısaltılmış karşılama mesajı ile karşılanmış olmalısınız: `}<em parentName="p">{`“Merhaba maceracı! Gryffinber’in sihirli dünyasına tekrar hoşgeldin!”`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      