import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Diyalogu başlatalım`}</h2>
    <p>{`Kullanıcılar, Action'larınız ile diyaloglarını `}<em parentName="p">{`invokation`}</em>{` (çağrı) ile başlatıyorlar. Örneğin `}<em parentName="p">{`Türkiye Gezgini`}</em>{` isimli bir Action'ınız varsa, kullanıcılar "Hey Google, `}<em parentName="p">{`Türkiye Gezgini`}</em>{` ile konuş" demek suretiyle Action'ınızı çağırabilirler.`}</p>
    <p>{`Yayınlamak istediğiniz Action'larınıza bu şekilde çağrı ismi vermeniz gerekiyor ancak test aşamasındaki Action'larınızı "Hey Google, `}<em parentName="p">{`Test Uygulamam`}</em>{` ile konuş" demek suretiyle hem simülatörde hem de cihazda test edebilirsiniz.`}</p>
    <p>{`Kullanıcı sesli uygulamanızı çağırdığında onu nasıl karşılayacağınızı belirlemek için `}<em parentName="p">{`main invocation`}</em>{`'ı düzenlemeniz gerekiyor.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Main invocation:`}</strong>{` Kullancıların Action ile konuşma başlatmak için kullandıkları giriş noktası. Kullanıcı "Hey Google, 'uygulama ismi' ile konuş" veya benzeri bir şey söylediğinde main invocation tetiklenir ve kullanıcıya cevap verir.`}</p>
    </blockquote>
    <p>{`Varsayılan olarak Actions Builder main invocation için genel geçer bir `}<em parentName="p">{`prompt`}</em>{` (cevap) vermektedir.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Prompt:`}</strong>{` Action'ın kullanıcıya verdiği cevap. Prompt yazı ve sesten oluşan simple response (basit cevap) olabileceği gibi, kart ve resimler içeren `}<em parentName="p">{`rich response`}</em>{` da olabilir.
Kullanıcıya tam ekran görseller şekliden cevap vermek için `}<strong parentName="p">{`Canvas`}</strong>{` isimli özel cevap türünü de kullanabilirsiniz.`}</p>
    </blockquote>
    <p>{`Bir sonraki bölümde, Actions konsolda sesli uygulamanız için belirlenmiş olan main invocation'ı özelleştirmeyi öğreneceksiniz.`}</p>
    <h3>{`Main invocation'ı özelleştirmek`}</h3>
    <p>{`Action'ın tetiklendiğinde verdiği cevabı özelleştirmek için şu adımları izleyin:`}</p>
    <ol>
      <li parentName="ol">{`Soldaki menüden `}<strong parentName="li">{`Main invocation`}</strong>{`'ı seçin
`}<figure parentName="li" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "840px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50.476190476190474%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3gUH/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAFxABAAMAAAAAAAAAAAAAAAAAEAARQf/aAAgBAQABPyEqa//aAAwDAQACAAMAAAAQEA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAACAwEBAAAAAAAAAAAAAAABEQAQMVGR/9oACAEBAAE/EEjScHkOoNr/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Main Invocation'ı özelleştirmek",
              "title": "Main Invocation'ı özelleştirmek",
              "src": "/static/34b28de55acac84394cdb4b681486e18/bba1f/2.jpg",
              "srcSet": ["/static/34b28de55acac84394cdb4b681486e18/60342/2.jpg 210w", "/static/34b28de55acac84394cdb4b681486e18/65f94/2.jpg 420w", "/static/34b28de55acac84394cdb4b681486e18/bba1f/2.jpg 840w", "/static/34b28de55acac84394cdb4b681486e18/3ac88/2.jpg 1260w", "/static/34b28de55acac84394cdb4b681486e18/ba579/2.jpg 1680w", "/static/34b28de55acac84394cdb4b681486e18/0abb9/2.jpg 3838w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Main Invocation'ı özelleştirmek`}</figcaption>{`
  `}</figure></li>
      <li parentName="ol">{`Kod editöründe, `}<inlineCode parentName="li">{`speech`}</inlineCode>{` (konuşma) alanını şu karşılama mesajı ile değiştirin: `}<inlineCode parentName="li">{`Selam olsun maceracı! Efsanevi Gryffinberg'e hoşgeldin. Kıyafetlerine bakılırsa buralı değilsin. Destansı bir yolculuğa çıkmışsın.`}</inlineCode></li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Not:`}</strong>{` Prompt'ları düzenlemek için ister YAML ister JSON kullanabilirsiniz.`}</p>
    </blockquote>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><strong parentName="li">{`Save`}</strong>{`'e (Kaydet) basın.`}</li>
    </ol>
    <h3>{`Simülatorde main invocation'ı test etmek`}</h3>
    <p>{`Actions konsol, Action'larınızı web üzerinde test etmeniz için bir Simülatör sunuyor. Bu simülatör, cihazları ve özelliklerini taklit ederek Action ile sanki o Action'ı bir akıllı telefonda, hoparlörde, ekranda veya KaiOS'ta kullanıyormuş gibi konuşmanıza olanak sağlar.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Actions simülatör ile ilgili en güncel bilgiyi `}<a parentName="p" {...{
          "href": "https://developers.google.com/assistant/console/simulator"
        }}>{`Simulator Guide`}</a>{` sayfasında bulabilirsiniz.`}</p>
    </blockquote>
    <p>{`Şimdi, Action'ı çağırdığınızda yukarı belirlediğiniz prompt ile cevap vermeli. `}</p>
    <p>{`Action'ı test etmek için aşağıdaki adımları izleyin:`}</p>
    <ol>
      <li parentName="ol">{`Üst menüden `}<strong parentName="li">{`Test`}</strong>{`'e tıklayarak Simülatör'ü açın`}</li>
      <li parentName="ol">{`Action'ı çağırmak için sol üstteki kutucuğa `}<inlineCode parentName="li">{`"Test uygulamam ile konuş"`}</inlineCode>{`  yazın ve `}<strong parentName="li">{`Enter`}</strong>{`'a basın.
`}<figure parentName="li" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "840px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.57142857142858%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe5c0Gj/xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAEFAl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAWEAEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQEAAT8hFuX/2gAMAwEAAgADAAAAELMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxAAAwACAwAAAAAAAAAAAAAAAAERECExUaH/2gAIAQEAAT8Qr2vRKohHSFxj/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Actions simülatör",
              "title": "Actions simülatör",
              "src": "/static/710e8306ec3419a1bb0d8e43eaca3918/bba1f/3.jpg",
              "srcSet": ["/static/710e8306ec3419a1bb0d8e43eaca3918/60342/3.jpg 210w", "/static/710e8306ec3419a1bb0d8e43eaca3918/65f94/3.jpg 420w", "/static/710e8306ec3419a1bb0d8e43eaca3918/bba1f/3.jpg 840w", "/static/710e8306ec3419a1bb0d8e43eaca3918/3ac88/3.jpg 1260w", "/static/710e8306ec3419a1bb0d8e43eaca3918/ba579/3.jpg 1680w", "/static/710e8306ec3419a1bb0d8e43eaca3918/0abb9/3.jpg 3838w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Actions simülatör`}</figcaption>{`
  `}</figure></li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`İpucu:`}</strong>{` Kutucuğa yazmak yerine "Test uygulamam ile konuş" öneri butonuna da basabilir veya mikrofon simgesine tıklayarak "Test uygulamam ile konuş" diyebilirsiniz.`}</p>
    </blockquote>
    <p>{`Artık Action'ın main invocation'ı tetiklendiğinde, kullanıcı özelleştirdiğiniz hoşgeldiniz mesajını duyuyor. Bu aşamada diyalog, Asistan'ın cevabından sonra bitiyor. Bir sonraki adımda diyalogu devamettirmek için Action'ı nasıl geliştireceğinizi öğreneceksiniz.`}</p>
    <h3>{`Event loglarını görüntüleyin`}</h3>
    <p><strong parentName="p">{`Test`}</strong>{` sekmesindeyken, sağdaki panel `}<em parentName="p">{`event log`}</em>{`larını (olay günlüklerini) gösterir. Event loglarına bakarak diyalogun geçmişini görebilirsiniz. Her bir log, diyalogun o turunda gerçekleşen olayları gösterir.`}</p>
    <p>{`Action'ınızın şu an yalnızca kullanıcının girdisini ve Action'ın cevabını içeren tek bir event logu var.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "840px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.85714285714286%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3gUH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFhAAAwAAAAAAAAAAAAAAAAAAAAEQ/9oACAEBAAE/IRT/2gAMAwEAAgADAAAAEPPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhAAAgIDAAAAAAAAAAAAAAAAAREAEFFhkf/aAAgBAQABPxBbPaIYn//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Event logları",
            "title": "Event logları",
            "src": "/static/f157863962394e51715b466aec903b2d/bba1f/4.jpg",
            "srcSet": ["/static/f157863962394e51715b466aec903b2d/60342/4.jpg 210w", "/static/f157863962394e51715b466aec903b2d/65f94/4.jpg 420w", "/static/f157863962394e51715b466aec903b2d/bba1f/4.jpg 840w", "/static/f157863962394e51715b466aec903b2d/3ac88/4.jpg 1260w", "/static/f157863962394e51715b466aec903b2d/ba579/4.jpg 1680w", "/static/f157863962394e51715b466aec903b2d/eae94/4.jpg 1894w"],
            "sizes": "(max-width: 840px) 100vw, 840px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Event logları`}</figcaption>{`
  `}</figure></p>
    <p>{`Eğer event logundaki aşağı ok tuşuna basarsanız, konuşmanın o turunda gerçekleşen eventleri kronolojik sıra ile görebilirsiniz.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`user input`}</inlineCode>{`: Kullanıcı girdisi`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`interactionMatch`}</inlineCode>{`: Kullanıcının girdisi ile tetiklenmiş olan Action'ın main invocation cevabını gösterir. Eğer bu satırı oka tıklayarak genişletirseniz main invocation için eklediğiniz prompt'u görebilirsiniz.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`endConversation`}</inlineCode>{`: Main invocation için belirlenmiş olan `}<em parentName="li">{`transition`}</em>{` (geçiş) yapılacak diyalog durumunu ifade ediyor, şimdilik test Action'ında konuşma burada bitiyor. (Bu çalışmanın bir sonraki adımında transition'lara dair daha detaylı bilgi yer almaktadır.)`}</li>
    </ul>
    <p>{`Event logları uygulamanızın nasıl çalıştığını gösterir ve bir sorun olduğunda debug etmeyi kolaylaştırır. bir event'in detaylarını görmek için, aşağıda gösterildiği gibi event'in yanındaki ok tuşuna basın:`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "840px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.14285714285714%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3lAH/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAFxAAAwEAAAAAAAAAAAAAAAAAABEgIf/aAAgBAQABPyFGT//aAAwDAQACAAMAAAAQUM//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAACAQUBAAAAAAAAAAAAAAABEQAQITFBgaH/2gAIAQEAAT8QQk26oAGvInBiv//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Event loglarının detayları",
            "title": "Event loglarının detayları",
            "src": "/static/e4ec0e9d4848ef6093e8f21649cf9a91/bba1f/5.jpg",
            "srcSet": ["/static/e4ec0e9d4848ef6093e8f21649cf9a91/60342/5.jpg 210w", "/static/e4ec0e9d4848ef6093e8f21649cf9a91/65f94/5.jpg 420w", "/static/e4ec0e9d4848ef6093e8f21649cf9a91/bba1f/5.jpg 840w", "/static/e4ec0e9d4848ef6093e8f21649cf9a91/3ac88/5.jpg 1260w", "/static/e4ec0e9d4848ef6093e8f21649cf9a91/ba579/5.jpg 1680w", "/static/e4ec0e9d4848ef6093e8f21649cf9a91/09d99/5.jpg 1873w"],
            "sizes": "(max-width: 840px) 100vw, 840px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Event loglarının detayları`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      